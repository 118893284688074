<template>
  <n-layout class="h-full">
    <n-layout-content class="h-full">
      <div class="bg h-full">
        <Header />
        <div style="min-height: calc(100% - 5.875rem); padding-bottom: 1.25rem;">
          <div class="breadcrumb">
            <n-breadcrumb>
              <n-breadcrumb-item @click="toPage('/home')" separator=">">首页</n-breadcrumb-item>
              <n-breadcrumb-item @click="toPage('/school')" separator=">">A类院校</n-breadcrumb-item>
              <n-breadcrumb-item @click="toPage('/evaluation')" separator=">">南京大学</n-breadcrumb-item>
              <n-breadcrumb-item>综评结算</n-breadcrumb-item>
            </n-breadcrumb>
          </div>
          <div class="container" v-if="page == 0">
            <div class="d-title flex">
              <img src="../../assets/images/js.png">
              <p>综评结算</p>
            </div>
            <div class="base-area">
              <div class="a-title">
                基础信息
              </div>
              <div class="info flex">
                <div class="item" v-for="item in 4">
                  <div class="label">
                    考生身份
                  </div>
                  <div class="value">
                    高一学生
                  </div>
                </div>
              </div>
            </div>
            <div class="base-area">
              <div class="a-title">
                五大学科竞赛奖项
              </div>
              <div class="info-awards flex">
                <div class="item">
                  <div class="head">
                    全国中学生数学奥林匹克竞赛
                  </div>
                  <p>省级一、二、三名（2022年）</p>
                  <p>国家级一、二、三名（2022年）</p>
                </div>
              </div>
            </div>
            <div class="base-area">
              <div class="a-title">
                全国竞赛活动奖项 (自然科学类)
              </div>
              <div class="info flex">
                <div class="item">
                  <p>.......</p>
                </div>
              </div>
            </div>
            <div class="base-area">
              <div class="a-title">
                创新潜质
              </div>
              <div class="info-awards flex">
                <div class="item">
                  <div class="head">
                    某一学科领域具有特殊才能者
                  </div>
                  <p>已上传材料</p>
                  <p>领域技能获奖材料***.docx</p>
                </div>
              </div>
            </div>
            <div class="list">
              <div class="item">
                <div class="row flex">
                  <div class="left">
                    综合评价录取
                  </div>
                  <div class="right">
                    <span>¥</span>4980
                  </div>
                </div>
                <div class="row-2 flex">
                  <div class="left">
                    数量：1
                  </div>
                  <div class="right">
                    待支付
                  </div>
                </div>
              </div>
            </div>
            <div class="foot flex">
              <div class="btn" @click="next">
                立即支付
              </div>
            </div>
          </div>
          <div class="container" v-else>
            <div class="d-title flex">
              <img src="../../assets/images/js.png">
              <p>综评结算</p>
            </div>
            <div class="result">
              <img src="../../assets/images/result.png">
              <p>综合评算已支付，报告结果会在xx个工作日完成评算，订单可在“个人中心”>“我的订单”中查看</p>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </n-layout-content>
  </n-layout>
</template>

<script setup>
import { ref } from 'vue'
import Header from '@/components/Header'
import Footer from '@/components/Footer'
import { useRouter } from 'vue-router'
const router = useRouter()
const page = ref(0)
function toPage(path) {
  router.push(path)
}
function next() {
  if (page.value == 0) {
    page.value++
  }
}
</script>

<style lang="scss" scoped>
.breadcrumb {
  width: 37.5rem;
  margin: 0 auto;
  margin-top: 0.75rem;
}

.container {
  width: 37.5rem;
  min-height: 21.0625rem;
  margin: 0 auto;
  margin-top: 0.5rem;
  background: #FFFFFF;
  box-shadow: 0px 0 0.125rem 0px rgba(0,0,0,0.09);
  border-radius: 0.25rem;
  padding: 0.71875rem;
  position: relative;

  .result {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    min-height: 100%;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;

    img {
      width: 4.5rem;
    }

    p {
      width: 13.21875rem;
      font-size: 0.4375rem;
      font-family: SourceHanSansCN, SourceHanSansCN;
      color: #061B5C;
      text-align: center;
    }
  }

  .d-title {
    align-items: center;
    margin-bottom: 0.75rem;
    img {
      width: 0.75rem;
      height: 0.75rem;
      margin-right: 0.1875rem;
    }

    p {
      font-size: 0.53125rem;
      font-family: SourceHanSansCN, SourceHanSansCN;
      font-weight: 600;
      color: #081D5D;
    }
  }

  .base-area {
    .a-title {
      font-size: 0.46875rem;
      font-family: SourceHanSansCN, SourceHanSansCN;
      font-weight: 500;
      color: #333333;
      height: 1.3125rem;
      line-height: 1.3125rem;
      padding-left: 0.5rem;
      box-sizing: border-box;
      background: linear-gradient(270deg, rgba(255,255,255,0.36) 0%, rgba(160,178,233,0.36) 100%);
    }

    .info-awards {
      padding-bottom: 0.5rem;
      .item {
        width: 8.125rem;
        height: 4.40625rem;
        background: #FFFFFF;
        border-radius: 0.125rem;
        border: 1px solid #ECECF3;
        margin-top: 0.75rem;
        cursor: pointer;
        position: relative;

        .checked {
          position: absolute;
          width: 0.6875rem;
          height: 0.625rem;
          background: #D44C3A;
          right: 0;
          svg {
            width: 0.6875rem;
            color: #fff;

          }
        }

        &.active {
          border-color: #D44C3A;
        }

        .head {
          font-size: 0.4375rem;
          font-family: SourceHanSansCN, SourceHanSansCN;
          font-weight: 500;
          color: #081D5D;
          height: 1.375rem;
          line-height: 1.375rem;
          border-bottom: 1px solid #ECECF3;
          padding: 0 0.5rem;
        }

        p {
          font-size: 0.4375rem;
          font-family: SourceHanSansCN, SourceHanSansCN;
          color: #333333;
          padding: 0 0.5rem;
          margin-top: 0.375rem;
        }
      }
    }

    .info {
      .item {
        padding: 0.5rem;
        .label {
          font-size: 0.46875rem;
          font-family: SourceHanSansCN, SourceHanSansCN;
          font-weight: 500;
          color: #081D5D;
        }

        .value {
          font-size: 0.46875rem;
          font-family: SourceHanSansCN, SourceHanSansCN;
          color: #333333;
          margin-top: 0.125rem;
        }
      }
    }


  }

  .list {
    .item {
      .row,
      .row-2 {
        justify-content: space-between;
      }

      .row {
        align-items: center;
        .left {
          font-size: 0.5rem;
          font-family: SourceHanSansCN, SourceHanSansCN;
          color: #061B5C;
        }

        .right {
          font-size: 0.8125rem;
          font-family: Rubik, Rubik;
          font-weight: 600;
          color: #061B5C;
          span {
            font-size: 0.4375rem;
          }
        }
      }

      .row-2 {
        .left,
        .right {
          font-size: 0.5rem;
          font-family: SourceHanSansCN, SourceHanSansCN;
          color: #999999;
        }

        .right {
          color: #FF6E00;
        }
      }

      background: #F9F9FC;
      border-radius: 0.1875rem;
      padding: 0.5rem 0.75rem;
      margin-bottom: 0.75rem;
    }
  }

  .foot {
    justify-content: flex-end;
    margin-top: 0.75rem;
    .btn {
      width: 5rem;
      height: 1.4375rem;
      background: #D44C3A;
      border-radius: 0.0625rem;
      font-size: 0.4375rem;
      font-family: SourceHanSansCN, SourceHanSansCN;
      color: #FFFFFF;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
    }
  }
}

@media screen and (max-width: 1024px) {
  .breadcrumb {
    display: none;
  }
}

@media screen and (max-width: 680px) {
  .container {
    width: calc(100% - 30px);
    margin: 0 auto;
    margin-top: 15px;
  }
}

@media screen and (max-width: 610px) {
  .info-awards {
    .item {
      width: 100%!important;
    }
  }
}

.mt-25 {
  margin-top: 0.78125rem;
}

.mt-16 {
  margin-top: 0.5rem;
}
</style>
