<template>
  <n-layout class="h-full">
    <n-layout-content class="h-full">
      <div class="bg h-full">
        <Header />
        <div style="min-height: calc(100% - 5.875rem); padding-bottom: 1.25rem;">
          <h1 class="title">全方位评测指导 入学理想高校</h1>
          <p class="intro">
            我们基于对学生进行科学测评的结果，在全面解析综合素质评价招生政策的基础上，提供给学生全方位的个性化服务，指导学生准备申请材料为学生制定个性化电请方案，提升学生的综合复试能力。专业规划团队会根据学生的实际情况和要求，与学生和家长进行一对一的沟通与指导,充分帮助学生顺利通过特殊类型招生申请,成功进入理想高校.
          </p>
          <div class="btns">
            <a class="red" href="javascript:void(0)">
              <h2>测前准备</h2>
              <p>测前准备</p>
            </a>
            <a class="red" href="javascript:void(0)" @click="toPage('/evaluation')">
              <h2>我要测评</h2>
              <p>分析适合报考高校</p>
            </a>
            <a class="blue" href="javascript:void(0)">
              <h2>点击学习</h2>
              <p>综合素质培训课程</p>
            </a>
            <a class="blue" href="javascript:void(0)">
              <h2>奖项适配院校</h2>
              <p>奖项适配院校</p>
            </a>
          </div>
          <div class="breadcrumb">
            <n-breadcrumb>
              <n-breadcrumb-item @click="toHome" separator=">">首页</n-breadcrumb-item>
              <n-breadcrumb-item>A类院校</n-breadcrumb-item>
            </n-breadcrumb>
          </div>
          <div class="schools">
            <div class="d-title flex">
              <img src="../../assets/images/directory.png">
              <p>江苏省综评院校名录（A类院校）</p>
            </div>
            <div class="list flex">
              <div class="item">
                <div class="top-area">
                  <div class="head flex">
                    <div class="avatar">
                      <img src="">
                    </div>
                    <p>深圳北理莫斯科大学</p>
                  </div>
                </div>
                <div class="bottom-area">
                  <p class="s-intro">志存高远，全面发展，具有外语学科特长与兴</p>
                  <a href="#">https://www.smbu.edu.cn/</a>
                </div>
              </div>
              <div class="item">
                <div class="top-area">
                  <div class="head flex">
                    <div class="avatar">
                      <img src="">
                    </div>
                    <p>深圳北理莫斯科大学</p>
                  </div>
                </div>
                <div class="bottom-area">
                  <p class="s-intro">志存高远，全面发展，具有外语学科特长与兴</p>
                  <a href="#">https://www.smbu.edu.cn/</a>
                </div>
              </div>
              <div class="item">
                <div class="top-area">
                  <div class="head flex">
                    <div class="avatar">
                      <img src="">
                    </div>
                    <p>深圳北理莫斯科大学</p>
                  </div>
                </div>
                <div class="bottom-area">
                  <p class="s-intro">志存高远，全面发展，具有外语学科特长与兴</p>
                  <a href="#">https://www.smbu.edu.cn/</a>
                </div>
              </div>
              <div class="item">
                <div class="top-area">
                  <div class="head flex">
                    <div class="avatar">
                      <img src="">
                    </div>
                    <p>深圳北理莫斯科大学</p>
                  </div>
                </div>
                <div class="bottom-area">
                  <p class="s-intro">志存高远，全面发展，具有外语学科特长与兴</p>
                  <a href="#">https://www.smbu.edu.cn/</a>
                </div>
              </div>
              <div class="item">
                <div class="top-area">
                  <div class="head flex">
                    <div class="avatar">
                      <img src="">
                    </div>
                    <p>深圳北理莫斯科大学</p>
                  </div>
                </div>
                <div class="bottom-area">
                  <p class="s-intro">志存高远，全面发展，具有外语学科特长与兴</p>
                  <a href="#">https://www.smbu.edu.cn/</a>
                </div>
              </div>
              <div class="item">
                <div class="top-area">
                  <div class="head flex">
                    <div class="avatar">
                      <img src="">
                    </div>
                    <p>深圳北理莫斯科大学</p>
                  </div>
                </div>
                <div class="bottom-area">
                  <p class="s-intro">志存高远，全面发展，具有外语学科特长与兴</p>
                  <a href="#">https://www.smbu.edu.cn/</a>
                </div>
              </div>
              <div class="item">
                <div class="top-area">
                  <div class="head flex">
                    <div class="avatar">
                      <img src="">
                    </div>
                    <p>深圳北理莫斯科大学</p>
                  </div>
                </div>
                <div class="bottom-area">
                  <p class="s-intro">志存高远，全面发展，具有外语学科特长与兴</p>
                  <a href="#">https://www.smbu.edu.cn/</a>
                </div>
              </div>
              <div class="item">
                <div class="top-area">
                  <div class="head flex">
                    <div class="avatar">
                      <img src="">
                    </div>
                    <p>深圳北理莫斯科大学</p>
                  </div>
                </div>
                <div class="bottom-area">
                  <p class="s-intro">志存高远，全面发展，具有外语学科特长与兴</p>
                  <a href="#">https://www.smbu.edu.cn/</a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </n-layout-content>
  </n-layout>
</template>

<script setup>
import Header from '@/components/Header'
import Footer from '@/components/Footer'
import { useRouter } from 'vue-router'
const router = useRouter()
function toHome() {
  router.push('/home')
}
</script>

<style lang="scss" scoped>
.breadcrumb {
  width: 37.5rem;
  margin: 0 auto;
  margin-top: 0.75rem;
}

.title {
  font-size: 1.25rem;
  font-family: SourceHanSansCN, SourceHanSansCN;
  font-weight: bold;
  color: #061B5C;
  line-height: 1.8125rem;
  letter-spacing: 0.03125rem;
  text-align: center;
  margin-top: 1.96875rem;
  margin-bottom: 0.625rem;
}

.intro {
  font-size: 0.375rem;
  font-family: SourceHanSansCN, SourceHanSansCN;
  color: #666666;
  line-height: 0.5625rem;
  text-align: center;
  max-width: 29.84375rem;
  margin: 0 auto;
  margin-bottom: 1.0625rem;
}

.btns {
  display: flex;
  justify-content: center;

  a {
    width: 5.25rem;
    height: 1.8125rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 0.0625rem;

    h2 {
      font-size: 0.5625rem;
      font-family: SourceHanSansCN, SourceHanSansCN;
      font-weight: bold;
      color: #FFFFFF;
    }

    p {
      font-size: 0.375rem;
      font-family: SourceHanSansCN, SourceHanSansCN;
      color: #FFFFFF;
    }
  }

  .red {
    background: linear-gradient(270deg, #D44C3A 0%, #F37457 100%);
    margin-right: 0.78125rem;
  }

  .blue {
    background: linear-gradient(270deg, #061B5C 0%, #33457B 100%);
    margin-right: 0.78125rem;
  }
}

.schools {
  width: 37.5rem;
  min-height: 21.0625rem;
  margin: 0 auto;
  margin-top: 0.5rem;
  padding: 0.71875rem;
  background: #FFFFFF;
  box-shadow: 0px 0 0.125rem 0px rgba(0, 0, 0, 0.09);
  border-radius: 0.25rem;

  .d-title {
    align-items: center;
    margin-bottom: 0.75rem;

    img {
      width: 0.75rem;
      height: 0.75rem;
      margin-right: 0.1875rem;
    }

    p {
      font-size: 0.53125rem;
      font-family: SourceHanSansCN, SourceHanSansCN;
      font-weight: 600;
      color: #081D5D;
    }
  }

  .list {
    flex-wrap: wrap;
    justify-content: space-between;

    &::after {
      content: "";
      flex: auto;
      /* 让伪元素占据多余的空间，将最后一个元素推到左边 */
      margin-left: 1rem;
    }

    .item {
      width: calc(33.33% - 1rem);
      border-radius: 0.125rem;
      margin-bottom: 1.125rem;
      overflow: hidden;
      margin-right: auto;

      &:nth-last-child(2) {
        margin-right: 1rem;
      }
    }

    .top-area {
      position: relative;
      width: 100%;
      height: 4.875rem;
      background: url(https://img0.baidu.com/it/u=2332300470,3086281722&fm=253&fmt=auto&app=138&f=JPEG?w=750&h=500)no-repeat;
      background-size: cover;
      background-position: center;
      padding: 0.375rem;
      box-sizing: border-box;

      &:after {
        content: '';
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        background: rgba(6, 27, 92, 0.8);

      }

      .head {
        position: relative;
        align-items: center;
        z-index: 99;

        .avatar {
          width: 1.125rem;
          height: 1.125rem;
          background: #FFFFFF;
          border-radius: 0.125rem;
          border: 0.03125rem solid #D4D9DF;
          margin-right: 0.375rem;
        }

        p {
          font-size: 0.4375rem;
          font-family: SourceHanSansCN, SourceHanSansCN;
          color: #fff;
        }
      }
    }

    .bottom-area {
      width: 100%;
      height: 3.5625rem;
      background: linear-gradient(360deg, #F0F5FF 0%, #F2F6FF 100%);

      font-size: 12px;
      font-family: SourceHanSansCN, SourceHanSansCN;
      color: #666666;
      padding: 0.375rem;
      box-sizing: border-box;

      a {
        color: #D44C3A;
      }
    }
  }
}

@media screen and (max-width: 961px) {
  .breadcrumb {
    display: none;
  }

  .schools {

    width: calc(100% - 30px);
    margin: 0 auto;
    margin-top: 15px;
  }
}

@media screen and (max-width: 781px) {
  .schools {
    width: calc(100% - 30px);
    margin: 0 auto;
    margin-top: 15px;

    .list {
      .item {
        width: calc(50% - 1rem);
      }
    }
  }
}

@media screen and (max-width: 531px) {
  .schools {
    width: calc(100% - 30px);
    margin: 0 auto;
    margin-top: 15px;

    .list {
      .item {
        width: 100%;
      }
    }
  }
}

.mt-25 {
  margin-top: 0.78125rem;
}

.mt-16 {
  margin-top: 0.5rem;
}</style>
