export default {
  namespace: 'true',
  state() {
    return {
      login: false,
    }
  },
  mutations: {
    login(state, visible) {
      state.login = visible
    }
  },
  actions: {
    setLogin(context, visible) {
      context.commit('login', visible);
    },
  },
  getters: {
    login(state) {
      return state.login
    }
  }
}
