<template>
  <n-layout class="h-full">
    <n-layout-content class="h-full">
      <div class="h-full bg">
        <Header hide-right />
        <div class="content"  style="min-height: calc(100% - 5.875rem);">
          <div class="title">
            <img src="/img/login-icon-1.png">
            <h1>全方位评测指导入学理想高校</h1>
          </div>
          <div class="login-area">
            <p class="login-title">用户登录</p>
            <div class="base-input">
              <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 24 24"><path d="M16 1H8C6.34 1 5 2.34 5 4v16c0 1.66 1.34 3 3 3h8c1.66 0 3-1.34 3-3V4c0-1.66-1.34-3-3-3zm-2 20h-4v-1h4v1zm3.25-3H6.75V4h10.5v14z" fill="currentColor"></path></svg>
              <input v-model="phone" type="text" placeholder="输入手机号">
            </div>
            <div class="pass-input">
              <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 512 512"><path d="M368 192h-16v-80a96 96 0 1 0-192 0v80h-16a64.07 64.07 0 0 0-64 64v176a64.07 64.07 0 0 0 64 64h224a64.07 64.07 0 0 0 64-64V256a64.07 64.07 0 0 0-64-64zm-48 0H192v-80a64 64 0 1 1 128 0z" fill="currentColor"></path></svg>
              <input v-model="password" type="text" placeholder="输入验证码">
              <p>获取验证码</p>
            </div>
            <div class="agreement">
              <div class="flex" @click="check = !check">
                <div class="check">
                  <svg v-if="check" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 24 24"><g fill="none"><path d="M12 2c5.523 0 10 4.477 10 10s-4.477 10-10 10S2 17.523 2 12S6.477 2 12 2zm3.22 6.97l-4.47 4.47l-1.97-1.97a.75.75 0 0 0-1.06 1.06l2.5 2.5a.75.75 0 0 0 1.06 0l5-5a.75.75 0 1 0-1.06-1.06z" fill="currentColor"></path></g></svg>
                </div>
                <span>登录即表示您同意并遵守</span>
              </div>

              <a href="javascript: void">用户协议</a>
            </div>
            <a @click="toHome" class="confim" href="javascript: void">立即登录</a>
            <div class="wechat">
              <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 512 512"><path data-name="XMLID 501 -1" d="M408.67 298.53a21 21 0 1 1 20.9-21a20.85 20.85 0 0 1-20.9 21m-102.17 0a21 21 0 1 1 20.9-21a20.84 20.84 0 0 1-20.9 21m152.09 118.86C491.1 394.08 512 359.13 512 319.51c0-71.08-68.5-129.35-154.41-129.35s-154.42 58.27-154.42 129.35s68.5 129.34 154.42 129.34c17.41 0 34.83-2.33 49.92-7c2.49-.86 3.48-1.17 4.64-1.17a16.67 16.67 0 0 1 8.13 2.34L454 462.83a11.62 11.62 0 0 0 3.48 1.17a5 5 0 0 0 4.65-4.66a14.27 14.27 0 0 0-.77-3.86c-.41-1.46-5-16-7.36-25.27a18.94 18.94 0 0 1-.33-3.47a11.4 11.4 0 0 1 5-9.35" fill="currentColor"></path><path data-name="XMLID 505 -7" d="M246.13 178.51a24.47 24.47 0 0 1 0-48.94c12.77 0 24.38 11.65 24.38 24.47c1.16 12.82-10.45 24.47-24.38 24.47m-123.06 0A24.47 24.47 0 1 1 147.45 154a24.57 24.57 0 0 1-24.38 24.47M184.6 48C82.43 48 0 116.75 0 203c0 46.61 24.38 88.56 63.85 116.53C67.34 321.84 68 327 68 329a11.38 11.38 0 0 1-.66 4.49C63.85 345.14 59.4 364 59.21 365s-1.16 3.5-1.16 4.66a5.49 5.49 0 0 0 5.8 5.83a7.15 7.15 0 0 0 3.49-1.17L108 351c3.49-2.33 5.81-2.33 9.29-2.33a16.33 16.33 0 0 1 5.81 1.16c18.57 5.83 39.47 8.16 60.37 8.16h10.45a133.24 133.24 0 0 1-5.81-38.45c0-78.08 75.47-141 168.35-141h10.45C354.1 105.1 277.48 48 184.6 48" fill="currentColor"></path></svg>
            </div>
          </div>
        </div>
      </div>

    </n-layout-content>
  </n-layout>
</template>

<script setup>
import Header from '@/components/Header'
import { ref } from 'vue';
import { useRouter } from 'vue-router'
import { useStore } from "vuex"
const store = useStore()
const router = useRouter()
const check = ref(true)
const phone = ref(123)
const password = ref(123)
function toHome() {
  router.push('/home')
  store.dispatch('setLogin', true)
}
</script>

<style lang="scss" scoped>
.content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-left: 6.6rem;
  margin-right: 6.12rem;
  .title {
    img {
      width: 4.34rem;
      height: 4.29rem;
    }

    h1 {
      width: 10.93rem;
      font-size: 1.4375rem;
      font-family: SourceHanSansCN, SourceHanSansCN;
      font-weight: 600;
      color: #061B5C;
      line-height: 2.0625rem;
      letter-spacing: 1px;
    }
  }

  .login-area {
    position: relative;
    width: 13.375rem;
    height: 14.8125rem;
    background-color: #fff;
    border-radius: 5px;
    box-shadow: 0px 0px 3px rgba(0,0,0,0.1);
    box-sizing: border-box;
    padding: 0.75rem 1.25rem;

    .wechat {
      position: absolute;
      bottom: 1.5625rem;
      left: 50%;
      transform: translateX(-50%);
      cursor: pointer;
      svg {
        width: 0.75rem;
      }
    }

    .login-title {
      color: #061B5C;
      font-size: 0.75rem;
      margin-bottom: 0.625rem;
    }

    .pass-input {
      display: flex;
      justify-content: space-between;
      background-color: rgb(244, 244, 248);
      width: 100%;
      box-sizing: border-box;
      padding: 0.25rem 0.46875rem;
      svg {
        width: 0.59375rem;
        color: rgb(194, 194, 194);
      }

      input {
        width: calc(100% - 0.59375rem - 0.46875rem - 2.1875rem);
        font-size: 0.4375rem;
        background-color: transparent;
      }

      p {
        font-size: 0.4375rem;
        color: #D44C3A;
        cursor: pointer;
      }
    }

    .base-input {
      display: flex;
      justify-content: space-between;
      background-color: rgb(244, 244, 248);
      width: 100%;
      box-sizing: border-box;
      padding: 0.25rem 0.46875rem;
      margin-bottom: 0.46875rem;

      svg {
        width: 0.59375rem;
        color: rgb(194, 194, 194);
      }

      input {
        width: calc(100% - 0.375rem - 0.46875rem);
        font-size: 0.4375rem;
        background-color: transparent;
      }
    }

    .agreement {
      display: flex;
      margin-top: 0.46875rem;
      cursor: pointer;
      .check {
        margin-right: 0.1875rem;
        width: 0.625rem;
        height: 0.625rem;
        background-color: #dad8d8;
        border-radius: 50%;
        svg {
          width: 0.6875rem;
          color: #D44C3A;
          position: relative;
          top: -1px;
          left: -1px;
        }
      }

      span {
        font-size: 0.5rem;
        color: #666;
      }
      a {
        font-size: 0.5rem!important;
        color: #666!important;
      }
    }

    .confim {
      width: 100%;
      height: 1.125rem;
      margin-top: 1rem;
      display: flex;
      font-size: 0.4375rem;
      background-color: #D44C3A;
      align-items: center;
      justify-content: center;
      border-radius: 4px;
      color: #fff!important;
    }
  }
}

@media screen and (max-width: 1020px) {
  .content {
    margin-left: 15px;
    margin-right: 15px;
    justify-content: center;
    .title {
      display: none;
    }
  }

}
</style>
