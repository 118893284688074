import { createRouter, createWebHashHistory } from 'vue-router'
import Longin from '@/views/Longin'
import Home from '@/views/Home'
import School from '@/views/School'
import Me from '@/views/Me'
import Order from '@/views/Order'
import Settlement from '@/views/Settlement'
import Evaluation from '@/views/Evaluation'
const routes = [
  {
    path: "/",
    redirect: '/home',
  },
  {
    path: "/login",
    component: Longin,
  },
  {
    path: "/home",
    component: Home,
  },
  {
    path: "/school",
    component: School,
  },
  {
    path: "/me",
    component: Me,
  },
  {
    path: "/order",
    component: Order,
  },
  {
    path: "/settlement",
    component: Settlement,
  },
  {
    path: "/evaluation",
    component: Evaluation,
  },
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})


export default router
