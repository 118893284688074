<template>
  <div class="header flex">
    <div class="logo" @click="toPage('/home')">
      <img src="/img/logo.png">
    </div>
    <div class="btn" @click="toPage('/login')" v-if="!props.hideRight && !store.getters.login">
      登录/注册
    </div>
    <div class="info" @click="toPage('/me')" v-else-if="store.getters.login">
      admin
      <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 12 12"><g fill="none"><path d="M6.786 1.459a.903.903 0 0 0-1.572 0L1.122 8.628C.774 9.238 1.211 10 1.91 10H10.09c.698 0 1.135-.762.787-1.372l-4.092-7.17z" fill="currentColor"></path></g></svg>
    </div>
  </div>
</template>

<script setup>
import { useRouter } from 'vue-router'
const router = useRouter()
const props = defineProps({
  hideRight: Boolean,
});
import { useStore } from "vuex"
const store = useStore()

function toPage(path) {
  router.push(path)
}
</script>

<style lang="scss" scoped>
.header {
  padding: 0 1.25rem;
  padding-top: 0.65rem;
  justify-content: space-between;

  .logo {
    img {
      width: 7.06rem;
    }
    cursor: pointer;
  }

  .btn {
    font-size: 0.4375rem;
    font-family: SourceHanSansCN, SourceHanSansCN;
    color: #FFFFFF;
    width: 2.8125rem;
    height: 1rem;
    line-height: 1rem;
    text-align: center;
    background: #D44C3A;
    border-radius: 20px;
    cursor: pointer;
  }

  .info {
    font-size: 0.5rem;
    font-family: SourceHanSansCN, SourceHanSansCN;
    color: #666666;
    display: flex;
    cursor: pointer;

    svg {
      color: #000000;
      width: 0.375rem;
      transform: rotateX(180deg);
      margin-left: 0.3125rem;
      position: relative;
      top: -0.21875rem;
    }
  }
}
</style>
