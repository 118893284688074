<template>
  <n-layout class="h-full">
    <n-layout-content class="h-full">
      <div class="bg h-full">
        <Header />
        <div style="min-height: calc(100% - 5.875rem); padding-bottom: 1.25rem;">
          <h1 class="title">全方位评测指导 入学理想高校</h1>
          <p class="intro">
            我们基于对学生进行科学测评的结果，在全面解析综合素质评价招生政策的基础上，提供给学生全方位的个性化服务，指导学生准备申请材料为学生制定个性化电请方案，提升学生的综合复试能力。专业规划团队会根据学生的实际情况和要求，与学生和家长进行一对一的沟通与指导,充分帮助学生顺利通过特殊类型招生申请,成功进入理想高校.
          </p>
          <div class="btns">
            <a class="red" href="javascript:void(0)">
              <h2>测前准备</h2>
              <p>测前准备</p>
            </a>
            <a class="red" href="javascript:void(0)" @click="toPage('/evaluation')">
              <h2>我要测评</h2>
              <p>分析适合报考高校</p>
            </a>
            <a class="blue" href="javascript:void(0)">
              <h2>点击学习</h2>
              <p>综合素质培训课程</p>
            </a>
            <a class="blue" href="javascript:void(0)">
              <h2>奖项适配院校</h2>
              <p>奖项适配院校</p>
            </a>
          </div>
          <div class="breadcrumb">
            <n-breadcrumb>
              <n-breadcrumb-item @click="toPage('/home')" separator=">">首页</n-breadcrumb-item>
              <n-breadcrumb-item @click="toPage('/school')" separator=">">A类院校</n-breadcrumb-item>
              <n-breadcrumb-item>南京大学</n-breadcrumb-item>
            </n-breadcrumb>
          </div>
          <div class="container">
            <div class="d-title flex">
              <img src="../../assets/images/directory.png">
              <p>南京大学</p>
            </div>
            <div class="check-area" v-if="page == 0">
              <div class="row flex">
                <div class="label">
                  考生身份
                </div>
                <div class="check-opt flex">
                  <div @click="params_0 = 0" class="item flex" :class="{ active: params_0 == 0 }">
                    <div class="check-box"></div>
                    高一学生
                  </div>
                  <div @click="params_0 = 1" class="item flex" :class="{ active: params_0 == 1 }">
                    <div class="check-box"></div>
                    高二学生
                  </div>
                  <div @click="params_0 = 2" class="item flex" :class="{ active: params_0 == 2 }">
                    <div class="check-box"></div>
                    高三学生
                  </div>
                  <div @click="params_0 = 3" class="item flex" :class="{ active: params_0 == 3 }">
                    <div class="check-box"></div>
                    往届毕业生
                  </div>
                </div>
              </div>
              <div class="row flex">
                <div class="label">
                  身体素质
                </div>
                <div class="check-opt flex">
                  <div @click="params_1 = 0" class="item flex" :class="{ active: params_1 == 0 }">
                    <div class="check-box"></div>
                    体检合格
                  </div>
                  <div @click="params_1 = 1" class="item flex" :class="{ active: params_1 == 1 }">
                    <div class="check-box"></div>
                    色盲
                  </div>
                </div>
              </div>
              <div class="row flex">
                <div class="label">
                  选科
                </div>
                <div class="check-opt flex">
                  <div @click="params_2 = 0" class="item flex" :class="{ active: params_2 == 0 }">
                    <div class="check-box"></div>
                    物理
                  </div>
                  <div @click="params_2 = 1" class="item flex" :class="{ active: params_2 == 1 }">
                    <div class="check-box"></div>
                    历史
                  </div>
                </div>
              </div>
              <div class="row flex">
                <div class="label">
                  语言能力
                </div>
                <div class="check-opt flex">
                  <div @click="params_3 = 0" class="item flex" :class="{ active: params_3 == 0 }">
                    <div class="check-box"></div>
                    英语特长
                  </div>
                </div>
              </div>
            </div>
            <div class="awards" v-else>
              <div class="awards-title">
                五大学科竞赛奖项
              </div>
              <div class="opts flex">
                <div :class="{ active: seleced.includes(item) }" class="item" v-for="item in 8"
                  @click="seleced.push(item)">
                  <div class="checked" v-if="seleced.includes(item)">
                    <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
                      viewBox="0 0 24 24">
                      <path d="M5 12l5 5L20 7" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                        stroke-linejoin="round"></path>
                    </svg>
                  </div>
                  <div class="head">
                    全国中学生数学奥林匹克竞赛
                  </div>
                  <p>省级一、二、三名（2022年）</p>
                  <p>国家级一、二、三名（2022年）</p>
                </div>
              </div>
            </div>
            <div class="foot flex">
              <div class="btn" @click="next">
                下一页
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </n-layout-content>
  </n-layout>
</template>

<script setup>
import { ref } from 'vue'
import Header from '@/components/Header'
import Footer from '@/components/Footer'
import { useRouter } from 'vue-router'
const router = useRouter()
const params_0 = ref(0)
const params_1 = ref(0)
const params_2 = ref(0)
const params_3 = ref(0)
const page = ref(0)
const seleced = ref([])
function toPage(path) {
  router.push(path)
}
function next() {
  if (page.value == 0) {
    page.value++
  } else {
    toPage('/settlement')
  }
}
</script>

<style lang="scss" scoped>
.breadcrumb {
  width: 37.5rem;
  margin: 0 auto;
  margin-top: 0.75rem;
}

.title {
  font-size: 1.25rem;
  font-family: SourceHanSansCN, SourceHanSansCN;
  font-weight: bold;
  color: #061B5C;
  line-height: 1.8125rem;
  letter-spacing: 0.03125rem;
  text-align: center;
  margin-top: 1.96875rem;
  margin-bottom: 0.625rem;
}

.intro {
  font-size: 0.375rem;
  font-family: SourceHanSansCN, SourceHanSansCN;
  color: #666666;
  line-height: 0.5625rem;
  text-align: center;
  max-width: 29.84375rem;
  margin: 0 auto;
  margin-bottom: 1.0625rem;
}

.btns {
  display: flex;
  justify-content: center;

  a {
    width: 5.25rem;
    height: 1.8125rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 0.0625rem;

    h2 {
      font-size: 0.5625rem;
      font-family: SourceHanSansCN, SourceHanSansCN;
      font-weight: bold;
      color: #FFFFFF;
    }

    p {
      font-size: 0.375rem;
      font-family: SourceHanSansCN, SourceHanSansCN;
      color: #FFFFFF;
    }
  }

  .red {
    background: linear-gradient(270deg, #D44C3A 0%, #F37457 100%);
    margin-right: 0.78125rem;
  }

  .blue {
    background: linear-gradient(270deg, #061B5C 0%, #33457B 100%);
    margin-right: 0.78125rem;
  }
}

.container {
  width: 37.5rem;
  min-height: 21.0625rem;
  margin: 0 auto;
  margin-top: 0.5rem;
  background: #FFFFFF;
  box-shadow: 0px 0 0.125rem 0px rgba(0, 0, 0, 0.09);
  border-radius: 0.25rem;
  padding: 0.71875rem;

  .d-title {
    align-items: center;
    margin-bottom: 0.75rem;

    img {
      width: 0.75rem;
      height: 0.75rem;
      margin-right: 0.1875rem;
    }

    p {
      font-size: 0.53125rem;
      font-family: SourceHanSansCN, SourceHanSansCN;
      font-weight: 600;
      color: #081D5D;
    }
  }

  .tabs {
    justify-content: space-between;

    .btn {
      width: calc(50% - 0.375rem);
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 0.1875rem;
      padding: 0.75rem 0;
      cursor: pointer;

      &:first-child {
        background: rgba(230, 99, 75, 0.12);
        font-size: 0.5625rem;
        font-family: SourceHanSansCN, SourceHanSansCN;
        font-weight: 600;
        color: #D44C3A;
      }

      &:last-child {
        background: rgba(29, 48, 108, 0.12);
        font-size: 0.5625rem;
        font-family: SourceHanSansCN, SourceHanSansCN;
        font-weight: 600;
        color: #061B5C;
      }
    }
  }

  .check-area {
    background: #FFFFFF;
    background: #F9F9FC;
    border-radius: 0.1875rem;
    margin-top: 0.5rem;
    padding: 1rem;

    .row {
      margin-bottom: 1rem;

      .label {
        font-size: 0.4375rem;
        font-family: SourceHanSansCN, SourceHanSansCN;
        font-weight: 600;
        color: #081D5D;
        margin-right: 1rem;
        width: 1.8125rem;
        text-align: right;
      }

      .check-opt {
        font-size: 0.4375rem;
        font-family: SourceHanSansCN, SourceHanSansCN;
        color: #333333;
        cursor: pointer;

        .item {
          display: flex;
          align-items: center;
          margin-right: 1.46875rem;
          position: relative;
          min-width: 2.3125rem;


          &.active {
            .check-box {
              background-color: #D44C3A !important;
              border: 0 !important;
              position: relative;

              &:after {
                content: '';
                position: absolute;
                width: calc(100% - 8px);
                height: calc(100% - 8px);
                left: 4px;
                top: 4px;
                background-color: #fff;
                border-radius: 50%;
              }
            }
          }
        }

        .check-box {
          width: 0.4375rem;
          height: 0.4375rem;
          border: 1px solid #C0C5CA;
          border-radius: 50%;
          box-sizing: border-box;
          margin-right: 0.125rem;
        }
      }
    }
  }

  .awards {
    background: #FFFFFF;
    background: #F9F9FC;
    border-radius: 0.1875rem;
    margin-top: 0.5rem;
    padding: 0.5625rem;

    &-title {
      font-size: 0.46875rem;
      font-family: SourceHanSansCN, SourceHanSansCN;
      font-weight: 500;
      color: #333333;
      height: 1.3125rem;
      line-height: 1.3125rem;
      padding-left: 0.5rem;
      box-sizing: border-box;
      background: linear-gradient(270deg, rgba(255, 255, 255, 0.36) 0%, rgba(160, 178, 233, 0.36) 100%);
    }

    .opts {
      flex-wrap: wrap;
      justify-content: space-between;

      .item {
        width: 8.125rem;
        height: 4.40625rem;
        background: #FFFFFF;
        border-radius: 0.125rem;
        border: 1px solid #ECECF3;
        margin-top: 0.75rem;
        cursor: pointer;
        position: relative;

        .checked {
          position: absolute;
          width: 0.6875rem;
          height: 0.625rem;
          background: #D44C3A;
          right: 0;

          svg {
            width: 0.6875rem;
            color: #fff;

          }
        }

        &.active {
          border-color: #D44C3A;
        }

        .head {
          font-size: 0.4375rem;
          font-family: SourceHanSansCN, SourceHanSansCN;
          font-weight: 500;
          color: #081D5D;
          height: 1.375rem;
          line-height: 1.375rem;
          border-bottom: 1px solid #ECECF3;
          padding: 0 0.5rem;
        }

        p {
          font-size: 0.4375rem;
          font-family: SourceHanSansCN, SourceHanSansCN;
          color: #333333;
          padding: 0 0.5rem;
          margin-top: 0.375rem;
        }
      }
    }
  }

  .foot {
    justify-content: flex-end;
    margin-top: 0.75rem;

    .btn {
      width: 5rem;
      height: 1.4375rem;
      background: #D44C3A;
      border-radius: 0.0625rem;
      font-size: 0.4375rem;
      font-family: SourceHanSansCN, SourceHanSansCN;
      color: #FFFFFF;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
    }
  }
}



@media screen and (max-width: 1024px) {
  .breadcrumb {
    display: none;
  }

  .container {
    margin-top: 40px;
  }
}

@media screen and (max-width: 835px) {
  .breadcrumb {
    display: none;
  }
}

@media screen and (max-width: 680px) {
  .container {
    width: 100%;
    width: calc(100% - 30px);
    margin: 15px auto;

    .tabs {
      display: inline !important;

      .btn {
        width: 100%;

        &:last-child {
          margin-top: 15px;
        }
      }
    }
  }
}

.mt-25 {
  margin-top: 0.78125rem;
}

.mt-16 {
  margin-top: 0.5rem;
}
</style>
