import { createStore } from 'vuex'
import userinfo from './userinfo'
export default createStore({
  state: {},
  getters: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
    userinfo
  }
})
