<template>
  <n-layout class="h-full">
    <n-layout-content class="h-full">
      <div class="bg h-full">
        <Header />
        <div style="min-height: calc(100% - 5.875rem);">
          <h1 class="title">全方位评测指导 入学理想高校</h1>
          <p class="intro">
            我们基于对学生进行科学测评的结果，在全面解析综合素质评价招生政策的基础上，提供给学生全方位的个性化服务，指导学生准备申请材料为学生制定个性化电请方案，提升学生的综合复试能力。专业规划团队会根据学生的实际情况和要求，与学生和家长进行一对一的沟通与指导,充分帮助学生顺利通过特殊类型招生申请,成功进入理想高校.
          </p>
          <div class="btns">
            <a class="red" href="javascript:void(0)">
              <h2>测前准备</h2>
              <p>测前准备</p>
            </a>
            <a class="red" href="javascript:void(0)" @click="toPage('/evaluation')">
              <h2>我要测评</h2>
              <p>分析适合报考高校</p>
            </a>
            <a class="blue" href="javascript:void(0)">
              <h2>点击学习</h2>
              <p>综合素质培训课程</p>
            </a>
            <a class="blue" href="javascript:void(0)">
              <h2>奖项适配院校</h2>
              <p>奖项适配院校</p>
            </a>
          </div>
          <div class="directory">
            <div class="d-title flex">
              <img src="../../assets/images/directory.png">
              <p>江苏省综评院校名录</p>
            </div>
            <div class="types flex mt-25">
              <div class="type-btn">
                A类院校
              </div>
              <div class="list">
                <div class="type-container">
                  <div class="type-item" @click="toPage('/school')" v-for="item in 12">
                    南京大学
                  </div>
                </div>
              </div>
              <div class="read" @click="toPage('/school')">
                查看
              </div>
            </div>
            <div class="types flex mt-16">
              <div class="type-btn">
                B类院校
              </div>
              <div class="list">
                <div class="type-container">
                  <div class="type-item" @click="toPage('/school')" v-for="item in 12">
                    南京大学
                  </div>
                </div>
              </div>
              <div class="read" @click="toPage('/school')">
                查看
              </div>
            </div>
          </div>

          <div class="positioning">
            <div class="d-title flex">
              <img src="../../assets/images/positioning.png">
              <p>适配综评院校</p>
            </div>
            <div class="content">
              <div class="options">
                <div class="item active">
                  成绩优异适配院校
                </div>
                <div class="item">
                  五大竞赛适配院校
                </div>
                <div class="item">
                  奖项适配院校
                </div>
                <div class="item">
                  荣誉表彰适配院校
                </div>
                <div class="item">
                  创新潜质适配院校
                </div>
              </div>
              <div class="schools flex">
                <div class="item" @click="toPage('/evaluation')" v-for="item in 13">
                  <div class="head flex">
                    <div class="avatar">
                      <img src="">
                    </div>
                    <p>香港中文大学（深圳）</p>
                  </div>
                  <p class="s-intro">
                    是一所享誉国际的公立研究型综合大学，在人文学科、数学、计算机科学、经济与金融、医学、法律、传媒、地理等领域堪称学术重镇，也是香港唯一同时拥有诺贝尔奖、菲尔兹奖、图灵奖、拉斯克奖及香农奖得主任教的大学。
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </n-layout-content>
  </n-layout>
</template>

<script setup>
import Header from '@/components/Header'
import Footer from '@/components/Footer'
import { useRouter } from 'vue-router'
const router = useRouter()
function toPage(path) {
  router.push(path)
}
</script>

<style lang="scss" scoped>
.title {
  font-size: 1.25rem;
  font-family: SourceHanSansCN, SourceHanSansCN;
  font-weight: bold;
  color: #061B5C;
  line-height: 1.8125rem;
  letter-spacing: 0.03125rem;
  text-align: center;
  margin-top: 1.96875rem;
  margin-bottom: 0.625rem;
}

.intro {
  font-size: 0.375rem;
  font-family: SourceHanSansCN, SourceHanSansCN;
  color: #666666;
  line-height: 0.5625rem;
  text-align: center;
  max-width: 29.84375rem;
  margin: 0 auto;
  margin-bottom: 1.0625rem;
}

.btns {
  display: flex;
  justify-content: center;

  a {
    width: 5.25rem;
    height: 1.8125rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 0.0625rem;

    h2 {
      font-size: 0.5625rem;
      font-family: SourceHanSansCN, SourceHanSansCN;
      font-weight: bold;
      color: #FFFFFF;
    }

    p {
      font-size: 0.375rem;
      font-family: SourceHanSansCN, SourceHanSansCN;
      color: #FFFFFF;
    }
  }

  .red {
    background: linear-gradient(270deg, #D44C3A 0%, #F37457 100%);
    margin-right: 0.78125rem;
  }

  .blue {
    background: linear-gradient(270deg, #061B5C 0%, #33457B 100%);
    margin-right: 0.78125rem;
  }
}

.d-title {
  align-items: center;

  img {
    width: 0.75rem;
    height: 0.75rem;
    margin-right: 0.1875rem;
  }

  p {
    font-size: 0.53125rem;
    font-family: SourceHanSansCN, SourceHanSansCN;
    font-weight: 600;
    color: #D44C3A;
  }
}


.directory {
  width: 37.5rem;
  height: 5.375rem;
  margin: 0 auto;
  background: #FFFFFF;
  box-shadow: 0px 0 0.125rem 0px rgba(0, 0, 0, 0.09);
  border-radius: 0.25rem;
  padding: 0.71875rem;
  box-sizing: border-box;
  margin-top: 1.5625rem;

  .types {
    justify-content: space-between;

    .type-btn {
      width: 3.125rem;
      height: 0.9375rem;
      line-height: 0.9375rem;
      background: linear-gradient(333deg, #FFF6F6 0%, #FFFFFF 100%);
      border-radius: 0.0625rem;
      border: 1px solid #D44C3A;
      font-size: 14px;
      font-family: SourceHanSansCN, SourceHanSansCN;
      font-weight: bold;
      color: #D44C3A;
      text-align: center;
      box-sizing: border-box;
      margin-right: 1.25rem;
    }

    .list {
      position: relative;
      width: calc(100% - 3.125rem - 1.25rem - 1.25rem - 1.0625rem);
      white-space: nowrap;
      overflow: hidden;

      .type-container {
        width: 1920px;

        .type-item {
          float: left;
          font-size: 0.4375rem;
          font-family: SourceHanSansCN, SourceHanSansCN;
          font-weight: 400;
          color: #7B838C;
          line-height: 0.9375rem;
          margin-right: 1.5625rem;
          cursor: pointer;
        }
      }

      &:after {
        content: '';
        position: absolute;
        width: 4.375rem;
        height: 100%;
        right: 0;
        top: 0;
        background: linear-gradient(-270deg, rgba(255, 255, 255, 0.09) 0%, #fff 100%);
      }

    }

    .read {
      font-size: 0.5rem;
      font-family: SourceHanSansCN, SourceHanSansCN;
      font-weight: 600;
      color: #3B83E7;
      line-height: 0.9375rem;
      cursor: pointer;
    }
  }
}

.positioning {
  width: 37.5rem;
  min-height: 21.0625rem;
  margin: 0 auto;
  margin-top: 0.9375rem;
  margin-bottom: 1.25rem;
  padding: 0.71875rem;
  background: #FFFFFF;
  box-shadow: 0px 0 0.125rem 0px rgba(0, 0, 0, 0.09);
  border-radius: 0.25rem;
  overflow: hidden;

  .content {
    margin-top: 0.75rem;

    .options {
      float: left;
      width: 7.5rem;
      background: #F6F6F8;
      border-radius: 0.1875rem;
      box-sizing: border-box;
      padding: 0.5rem 0.125rem;

      .item {
        font-size: 0.4375rem;
        font-family: SourceHanSansCN, SourceHanSansCN;
        font-weight: 600;
        color: #7B838C;
        padding: 0.25rem 0.375rem;

        &.active {
          background: #D44C3A;
          border-radius: 0.125rem;
          color: #fff;
        }
      }
    }

    .schools {
      float: right;
      width: calc(100% - 7.5rem - 0.75rem);
      flex-wrap: wrap;
      justify-content: space-between;

      .item {
        width: calc(33.333% - 0.5625rem);
        box-sizing: border-box;
        padding: 0.375rem;
        padding-bottom: 1rem;
        box-shadow: 0 0 0.125rem 0px rgba(0, 0, 0, 0.09);
        margin-bottom: 0.75rem;
        cursor: pointer;

        .head {
          align-items: center;

          .avatar {
            width: 1.125rem;
            height: 1.125rem;
            background: #FFFFFF;
            border-radius: 0.125rem;
            border: 0.03125rem solid #D4D9DF;
            margin-right: 0.375rem;
          }

          p {
            font-size: 0.4375rem;
            font-family: SourceHanSansCN, SourceHanSansCN;
            font-weight: 600;
            color: #333333;
          }
        }

        .s-intro {
          font-size: 0.375rem;
          font-family: SourceHanSansCN, SourceHanSansCN;
          color: #999999;
          line-height: 0.53125rem;
          margin-top: 0.375rem;
        }
      }
    }
  }
}

.mt-25 {
  margin-top: 0.78125rem;
}

.mt-16 {
  margin-top: 0.5rem;
}

@media screen and (max-width: 921px) {
  .directory {
    width: calc(100% - 30px);
    margin: 15px auto;
    height: auto;

    .types {
      display: inline;

      .type-btn {
        width: 100%;
        margin-top: 15px;
      }

      .list {
        width: 100%;

        &:after {
          display: none;
        }

        .type-container {
          width: 100%;
          display: flex;
          flex-wrap: wrap;
          justify-content: space-between;
        }

        .type-item {
          float: none !important;
          width: calc(50% - 10px);
          text-align: center;
          border: 1px solid rgba(0, 0, 0, 0.09);
          margin-top: 15px;
          margin-right: 0 !important;
        }
      }

      .read {
        text-align: center;
        border: 1px solid rgba(0, 0, 0, 0.09);
        margin-top: 9px;
      }
    }
  }

  .positioning {
    width: calc(100% - 30px);
    margin: 0 auto;
    padding-top: 15px;

    .options {
      float: none !important;
      width: 100% !important;

      .item {
        text-align: center;
      }

    }

    .schools {
      float: none !important;
      width: 100% !important;
      margin-top: 15px;
    }
  }
}

@media screen and (max-width: 628px) {
  .schools {
    .item {
      width: calc(50% - 0.5625rem) !important;
    }
  }
}</style>
