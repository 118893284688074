<template>
  <div class="footer">
    <div class="blue">
      <img src="/img/footer-logo.png">
    </div>
    <div class="red">

    </div>
  </div>
</template>

<script setup>

</script>

<style lang="scss" scoped>
.footer {
  .blue {
    height: 4.625rem;
    background-color: #061B5C;
    padding-top: 1.0625rem;
    box-sizing: border-box;
    img {
      width: 7.0625rem;
      margin-left: 1.25rem;
    }
  }

  .red {
    height: 1.25rem;
    background-color: #D44C3A;
  }
}
</style>
